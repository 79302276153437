export const javaHomeSeo = {
  title: 'Enterprise Software Development Mobile Web Backend',
  meta: [
    {
      name: 'keywords',
      content:
        'java software development, custom project development, custom software development, enterprise software development, dedicated teams, software outsouricng',
    },
    {
      name: 'description',
      content:
        ' Java took a significant place in the market of mobile and enterprise web development due to its cross-functionality, available libraries, and high scalability. ',
    },
  ],
};
