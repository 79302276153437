import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { ServiceInfo } from '@rumblefish/ui';
import React from 'react';
import type { CaseStudyInfoProps } from './CaseStudyInfo.types';

const PREFIX = 'CaseStudyInfo';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(
  ({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
    [`&.${classes.root}`]: {
      display: 'grid',
      gap: spacing(0, 4),
      alignItems: 'start',
      gridTemplateRows: '1fr 1fr',
      gridTemplateColumns: '1fr 1fr',

      [breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gap: spacing(2),
      },
    },
  })
);

export const CaseStudyInfo: React.FC<CaseStudyInfoProps> = ({
  collaborationTimeframe,
  technology,
  teamSize,
  services,
}) => {
  return (
    <StyledGrid container className={classes.root}>
      <ServiceInfo
        label="Collaboration timeframe:"
        description={collaborationTimeframe}
      />
      <ServiceInfo label="Technology:" description={technology} />
      <ServiceInfo label="Services:" description={services} />
      <ServiceInfo label="Team size:" description={teamSize} />
    </StyledGrid>
  );
};
