import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { colors, RichTextLandingPages as RichText } from '@rumblefish/ui';
import React from 'react';
import type { RefObject } from 'react';
import { PostsHeading } from '../../components/PostsHeading';
import { PostsMenu } from '../../components/PostsMenu';
import type { PostsProps } from './Posts.types';

const PREFIX = 'Posts';

const classes = {
  postsWrapper: `${PREFIX}-postsWrapper`,
  postsContentWrapper: `${PREFIX}-postsContentWrapper`,
  postsMenu: `${PREFIX}-postsMenu`,
  singlePost: `${PREFIX}-singlePost`,
};

const Root = styled('section')(
  ({
    theme: {
      spacing,
      template: { normalSectionWidth, webHeaderHeight },
    },
  }: {
    theme: Theme;
  }) => ({
    [`& .${classes.postsWrapper}`]: {
      display: 'grid',
      gridTemplateColumns: `1fr minmax(0, ${normalSectionWidth}) 1fr`,
      padding: spacing(4, 2),
      backgroundColor: colors.white,
    },

    [`& .${classes.postsContentWrapper}`]: {
      gridColumn: '2 / span 1',
    },

    [`& .${classes.postsMenu}`]: {
      position: 'relative',
    },

    [`& .${classes.singlePost}`]: {
      margin: spacing(4, 0),
      scrollMarginTop: `calc(${webHeaderHeight} + ${spacing(1)})`,

      '& h2': {
        marginBottom: spacing(4),
      },
    },
  })
);

export const Posts: React.FC<PostsProps> = ({ data }) => {
  if (!data) {
    console.error('No posts found');
    return <p>no posts</p>;
  }

  const firstPostTitle = data[0].title;

  const postsRefs = data.reduce((acc, value) => {
    acc[value.title] = React.createRef<HTMLDivElement>();
    return acc;
  }, {} as Record<string, RefObject<HTMLDivElement>>);

  const handleScroll = (title: string): void =>
    postsRefs[title].current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  return (
    <Root>
      <PostsHeading heading={firstPostTitle} />
      <div className={classes.postsWrapper}>
        <div className={classes.postsContentWrapper}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              {data.map((post, index) => (
                <div
                  className={classes.singlePost}
                  key={post.title}
                  ref={postsRefs[post.title]}>
                  {index !== 0 && (
                    <Typography variant="h2">{post.title}</Typography>
                  )}
                  <RichText content={post.description} />
                </div>
              ))}
            </Grid>
            <Hidden mdDown>
              <Grid item md={4} className={classes.postsMenu}>
                <PostsMenu
                  handleScroll={handleScroll}
                  titles={data.map((posts) => posts.title)}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>
    </Root>
  );
};
