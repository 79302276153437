import { styled } from '@mui/material/styles';
import React from 'react';
import ribbonLeft from '../../public/png/ribbonLeft.png';
import ribbonRight from '../../public/png/ribbonRight.png';

const RibbonLeftWrapper = styled('div')({
  position: 'absolute',
  left: '0',
  top: '12rem',

  [`& img`]: {
    width: '264px',
    height: '420px',
  },
});

const RibbonRightWrapper = styled('div')({
  position: 'absolute',
  right: '0',
  top: '4rem',

  [`& img`]: {
    width: '262px',
    height: '420px',
  },
});

export const Ribbons: React.FC = () => {
  return (
    <div>
      <RibbonLeftWrapper>
        <img src={ribbonLeft} alt="ribbon" />
      </RibbonLeftWrapper>
      <RibbonRightWrapper>
        <img src={ribbonRight} alt="ribbon" />
      </RibbonRightWrapper>
    </div>
  );
};
