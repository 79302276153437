import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { ParagraphText, Section, SectionHeader } from '@rumblefish/ui';
import React from 'react';
import projectIcon from '../../public/png/customProject.png';
import dedicatedIcon from '../../public/png/dedicatedTeam.png';

const PREFIX = 'JavaSoftwareIntro';

const classes = {
  root: `${PREFIX}-root`,
  leftParagraph: `${PREFIX}-leftParagraph`,
  rightParagraph: `${PREFIX}-rightParagraph`,
};

const StyledJavaSoftwareIntroWrapper = styled('div')(
  ({ theme: { breakpoints, spacing } }: { theme: Theme }) => ({
    [`& .${classes.root}`]: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: spacing(4),
      alignItems: 'start',

      [breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },

    [`& .${classes.leftParagraph}`]: {
      gridColumn: '1 / span 1',
      [breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
      },
    },

    [`& .${classes.rightParagraph}`]: {
      gridColumn: '2 / span 1',
      [breakpoints.down('sm')]: {
        gridColumn: '1 / span 1',
        gridTemplateColumns: '1fr',
      },
    },
  })
);

export const JavaSoftwareIntro: React.FC = () => {
  return (
    <Section topOverlay>
      <StyledJavaSoftwareIntroWrapper>
        <div className={classes.root}>
          <SectionHeader
            title="JAVA Software Development"
            annotation="What do we do?"
            description="Rumble Fish helps entrepreneurs build and launch new software products. We deliver on-demand a ready-to-go developers’ team"
          />
          <ParagraphText
            className={classes.leftParagraph}
            title="Custom Project Development"
            description="At Rumble Fish, we recognize that every business and industry is different, which is why our experts work to build software solutions tailored to your needs. Rumble Fish Software Development uses Java to create custom software solutions for companies of all sizes, from startups, through medium-sized to enterprises. We are a Java software development company with 20+ years of experience. We help companies build sustainable Java-based software fast and cost-efficiently due to its security, portability, scalability and powerful frameworks."
            smallerBody
            icon={{ alt: 'custom project development', src: projectIcon }}
          />
          <ParagraphText
            className={classes.rightParagraph}
            title="Dedicated Teams and Specialists"
            description="The dedicated team of specialists at Rumble Fish ensures that partnering with us will speed up the process of delivering your project. Our experts can act as an extension to your in-house departments when you don't have the IT and engineering resources or time for lengthy and costly recruitment processes. Rumble Fish can take responsibility for the end-to-end development of your custom project. With more than 2 decades of experience, Rumble Fish Software Development delivers and maintains top-notch solutions in Java technologies. Java took a significant place in the market of mobile and corporate web development due to its cross-functionality, available libraries, and high scalability."
            smallerBody
            icon={{
              alt: 'Dedicated Teams and Specialists',
              src: dedicatedIcon,
            }}
          />
        </div>
      </StyledJavaSoftwareIntroWrapper>
    </Section>
  );
};
