import type { Theme } from '@mui/material';
import { Grid } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import {
  Divider,
  SectionHeader,
  LaptopSection,
  Section,
  RichTextLandingPages as RichText,
  TestimonialMain,
} from '@rumblefish/ui';
import React from 'react';
import { CaseStudyInfo } from '../../components/CaseStudyInfo';
import type { CaseStudyProps } from './CaseStudy.types';

const PREFIX = 'CaseStudy';

const classes = {
  caseStudyTextContainer: `${PREFIX}-caseStudyTextContainer`,
};

const Root = styled('section')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.caseStudyTextContainer}`]: {
    display: 'grid',
    gap: theme.spacing(6),
  },
}));

export const CaseStudy: React.FC<CaseStudyProps> = ({ data }) => {
  const {
    title,
    teamSize,
    technology,
    services,
    collaborationTimeframe,
    image,
    content1,
    content2,
    testimonial,
  } = data;

  return (
    <Root>
      <LaptopSection
        name="caseStudy"
        laptopImage={image}
        heading={
          <Grid className={classes.caseStudyTextContainer}>
            <SectionHeader title={title} annotation="Case Study" />
            <CaseStudyInfo
              teamSize={teamSize}
              technology={technology}
              services={services}
              collaborationTimeframe={collaborationTimeframe}
            />
            <Hidden lgDown>
              <Divider />
            </Hidden>
          </Grid>
        }>
        <RichText content={content1} />
      </LaptopSection>
      <Section type="narrow">
        <RichText content={content2} />
        <TestimonialMain
          role={testimonial.fields.role}
          image={testimonial.fields.image}
          name={testimonial.fields.name}
          content={testimonial.fields.content}
        />
      </Section>
    </Root>
  );
};
