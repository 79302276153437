import Hidden from '@mui/material/Hidden';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '@rumblefish/ui';
import React from 'react';
import railImage from '../../public/png/tail-4.png';
import type { PostsHeadingProps } from './PostsHeading.types';

const PREFIX = 'PostsHeading';

const classes = {
  postsHeadingWrapper: `${PREFIX}-postsHeadingWrapper`,
  postsHeadingText: `${PREFIX}-postsHeadingText`,
  postsImageWrapper: `${PREFIX}-postsImageWrapper`,
};

const Root = styled('div')(
  ({
    theme: {
      spacing,
      breakpoints,
      template: { normalSectionWidth },
    },
  }: {
    theme: Theme;
  }) => ({
    [`&.${classes.postsHeadingWrapper}`]: {
      color: colors.white,
      background: colors.navyBlue,
      display: 'grid',
      gridTemplateColumns: `1fr minmax(0, ${normalSectionWidth}) 1fr`,
      position: 'relative',
      padding: spacing(0, 2),
    },

    [`& .${classes.postsHeadingText}`]: {
      gridColumn: '2 / span 1',
      margin: `${spacing(5)} 0`,
      marginRight: spacing(40),

      [breakpoints.down('md')]: {
        marginRight: 0,
      },
    },

    [`& .${classes.postsImageWrapper}`]: {
      position: 'absolute',
      right: 0,
      top: '-8rem',
    },
  })
);

export const PostsHeading: React.FC<PostsHeadingProps> = ({ heading }) => {
  return (
    <Root className={classes.postsHeadingWrapper}>
      <Typography variant="h1" className={classes.postsHeadingText}>
        {heading}
      </Typography>
      <Hidden lgDown>
        <div className={classes.postsImageWrapper}>
          <img alt="" src={railImage} width={512} height={512} />
        </div>
      </Hidden>
    </Root>
  );
};
