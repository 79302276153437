import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {
  colors,
  GradientText,
  handleScroll,
  HeadlineLink,
} from '@rumblefish/ui';
import React from 'react';
import { Ribbons } from '../../components/Ribbons';
import background from '../../public/jpg/productVisionDotsWave.jpg';
import logo from '../../public/svg/logo.svg';
import type { ProductVisionProps } from './ProductVision.types';

const PREFIX = 'ProductVisionHeader';

const classes = {
  root: `${PREFIX}-root`,
  productVisionTextContainer: `${PREFIX}-productVisionTextContainer`,
  productVisionButtonsContainer: `${PREFIX}-productVisionButtonsContainer`,
  productVisionLinksContainer: `${PREFIX}-productVisionLinksContainer`,
  productVisionLinksSlash: `${PREFIX}-productVisionLinksSlash`,
};

const Root = styled('section')(
  ({ theme: { palette, breakpoints, spacing } }: { theme: Theme }) => ({
    [`& .${classes.root}`]: {
      marginTop: '-5rem',
      color: palette.primary.main,
      backgroundImage: `url(${background})`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',

      position: 'relative',
      padding: spacing(20, 3),
      [breakpoints.only('sm')]: {
        padding: spacing(14, 0),
      },
      [breakpoints.only('xs')]: {
        padding: spacing(8, 0),
      },
    },

    [`& .${classes.productVisionTextContainer}`]: {
      display: 'grid',
      gap: spacing(5),
      justifyContent: 'center',
      textAlign: 'center',
      zIndex: 1,
      [breakpoints.only('xs')]: {
        gap: spacing(5),
        margin: spacing(0, 6),
      },
    },

    [`& .${classes.productVisionButtonsContainer}`]: {
      display: 'grid',
      marginTop: spacing(8),
      gridAutoFlow: 'column',
      gap: spacing(2),
      justifyContent: 'center',
      [breakpoints.only('xs')]: {
        gridAutoFlow: 'row',
        marginTop: spacing(8),
      },
    },

    [`& .${classes.productVisionLinksContainer}`]: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: spacing(2),
      justifyContent: 'center',
      [breakpoints.only('xs')]: {
        gridAutoFlow: 'row',
      },
    },

    [`& .${classes.productVisionLinksSlash}`]: {
      fontWeight: 'bold',
      color: colors.lightblue,
      [breakpoints.only('xs')]: {
        display: 'none',
      },
    },
  })
);

export const ProductVisionHeader: React.FC<ProductVisionProps> = ({
  scrollRef,
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      md={8}
      className={classes.productVisionTextContainer}>
      <Typography variant="h1">
        You bring the product vision.
        <br />
        We bring
        <GradientText>product-building</GradientText>
        expertise
      </Typography>
      <div className={classes.productVisionLinksContainer}>
        <HeadlineLink linkText="JAVA Software Development" />
        <span className={classes.productVisionLinksSlash}>/</span>
        <Link underline="none" href="https://www.rumblefish.dev/services/">
          <HeadlineLink linkText="Check other services" />
        </Link>
      </div>
      <Grid className={classes.productVisionButtonsContainer}>
        <Button
          onClick={() => handleScroll(scrollRef)}
          variant="contained"
          aria-label="go to contact form"
          color="secondary">
          Estimate Project
        </Button>
        <Button
          variant="contained"
          aria-label="go to contact page"
          color="secondary"
          href="https://www.rumblefish.dev/contact/">
          Let's talk
        </Button>
      </Grid>
    </Grid>
  );
};

const StyledLogoContainer = styled('div')(
  ({
    theme: {
      breakpoints,
      template: { normalSectionWidth },
    },
  }) => ({
    display: 'grid',
    gridTemplateColumns: `1fr minmax(0, ${normalSectionWidth}) 1fr`,

    [breakpoints.down('md')]: {
      marginBottom: '3rem',
    },

    ['a']: {
      marginTop: '2rem',
      gridColumn: '2 / span 1',
      zIndex: 1,
      justifySelf: 'start',

      [breakpoints.down('md')]: {
        marginTop: '1.2rem',
        justifySelf: 'center',
      },
    },
  })
);

const LogoContainer = () => {
  return (
    <StyledLogoContainer>
      <a href="https://www.rumblefish.dev/">
        <img src={logo} width="207" height="64" alt="rumblefish logo" />
      </a>
    </StyledLogoContainer>
  );
};

export const ProductVision: React.FC<ProductVisionProps> = ({ scrollRef }) => {
  return (
    <Root>
      <LogoContainer />
      <Grid container justifyContent="center" className={classes.root}>
        <ProductVisionHeader scrollRef={scrollRef} />
        <Hidden mdDown>
          <Ribbons />
        </Hidden>
      </Grid>
    </Root>
  );
};
