import type { GetStaticProps } from 'next';
import { fetchCaseStudyData, fetchPostsData } from '../utils/api/fetchData';
import { Home } from '../views/Home';

export const getStaticProps: GetStaticProps = async () => {
  const caseStudy = await fetchCaseStudyData();
  const postsCollection = await fetchPostsData();

  return {
    props: { caseStudy, postsCollection },
  };
};

export default Home;
