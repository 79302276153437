import { styled } from '@mui/material/styles';
import type { Theme } from '@mui/material/styles';
import { colors, Divider } from '@rumblefish/ui';
import { NavLink } from '@rumblefish/ui/src/components/NavLink';
import React from 'react';
import type { PostsMenuProps } from './PostsMenu.types';

const PREFIX = 'PostsMenu';

const classes = {
  postsMenuWrapper: `${PREFIX}-postsMenuWrapper`,
};

const Root = styled('div')(
  ({
    theme: {
      spacing,
      template: { webHeaderHeight },
    },
  }: {
    theme: Theme;
  }) => ({
    [`&.${classes.postsMenuWrapper}`]: {
      background: colors.footerGrey,
      padding: spacing(3),
      marginBottom: spacing(4),
      borderRadius: spacing(1),
      '& *': {
        marginBottom: spacing(2),
      },

      position: 'sticky',
      top: `calc(${spacing(4)} + ${webHeaderHeight})`,
    },
  })
);

export const PostsMenu: React.FC<PostsMenuProps> = ({
  titles,
  handleScroll,
}) => {
  return (
    <Root className={classes.postsMenuWrapper}>
      {titles.map((title, index) => (
        <div key={title}>
          {index !== 0 && <Divider />}
          <NavLink onClick={() => handleScroll(title)} to="#!">
            {title}
          </NavLink>
        </div>
      ))}
    </Root>
  );
};
