import { LetsCollaborate, NextHeadWithMeta } from '@rumblefish/ui';
import { Footer } from '@rumblefish/ui/src/pageLayout/Footer';
import { useRef } from 'react';
import { CaseStudy } from '../CaseStudy';
import { JavaSoftwareIntro } from '../JavaSoftwareIntro';
import { Posts } from '../Posts';
import { ProductVision } from '../ProductVision';
import { javaHomeSeo } from './Home.seo';
import type { HomePageProps } from './Home.types';

export const Home = ({ caseStudy, postsCollection }: HomePageProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <NextHeadWithMeta {...javaHomeSeo} />
      <header>
        <ProductVision scrollRef={scrollRef} />
      </header>
      <main>
        <JavaSoftwareIntro />
        <CaseStudy data={caseStudy} />
        <Posts data={postsCollection} />
        <LetsCollaborate scrollRef={scrollRef} />
      </main>
      <Footer />
    </>
  );
};
